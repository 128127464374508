.burger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 25px;
  cursor: pointer;
  z-index: 9999;
  transform: scale(-1, 1);
  width:25px;
}

.burger-menu div {
  width: 100%;
  height: 3px;
  background-color: var(--color-dark);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.burger-menu .line:nth-child(2) {
  width: 80%;
}

.burger-menu .line:last-child {
  width: 60%;
}

.burger-menu.open .line:first-child {
  transform:rotate(45deg) translateX(8px) translateY(8px);
  background-color: var(--color-light);
}

.burger-menu.open .line:nth-child(2) {
  transform: rotate(-45deg);
  width:100%;
  background-color: var(--color-light);
}

.burger-menu.open .line:last-child {
  transform: rotate(45deg) translateX(-5px) translateY(-6px);
  opacity: 0;
  background-color: var(--color-light);
}


.burger-menu.open {
  position:Relative;
}

.menu-container {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 998;
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 80%;
  background-color: #1c1c1c;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.menu li {
  margin-bottom: 20px;
}

.menu a {
  color: var(--color-light);
  text-decoration: none;
  font-size: 3em;
  font-weight: bold;
  text-transform: uppercase;
}

@media only screen and (min-width:1024px) {
  .burger-menu {
    display:none;
  }
  .menu-desktop {
    display:flex;
  }
}

@media only screen and (max-width:1024px) {
  .burger-menu {
    display:flex;
  }
  .menu-desktop {
    display:none;
  }
}
