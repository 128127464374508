@tailwind base;
@tailwind components;
@tailwind utilities;



@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

:root {
  --color-dark: #1C1C1C;
  --color-light: #fff6e5; ;
  --color-white: #fff;
  --transition: all 400ms ease;
  --section-padding: clamp(5em, 21vh, 12em);
  --container-padding: clamp(2.5em, 8vw, 8em);
  --gap-padding: clamp(1.5em, 4vw, 2.5em);
  --color-border: rgba(28, 29, 32, 0.175)
}

html {
  scroll-behavior: smooth;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-dark);
  padding:2rem;
  font-size: clamp(16px, 1.2vw, 19px);
  
}

body, html {
  font-family: 'DM Sans', sans-serif;
}





h1 {
  font-size: calc(clamp(3.25em, 7vw, 8em) * 1)
}
h2 {
  font-size: 3.1vw;
  line-height: 3.1vw;
}

h3 {
  font-size:2.5em
}

h4 {
  font-size: clamp(1.55em, 2.3vw, 2.5em);
}
h5 {
  font-size:0.6em;
}
p, a, li, span {
  font-size:1em;
}







.main {
  width: 100%;
}



@media only screen and (min-width:639px) {
  .mobile {
    display:none;
  }
}