.header {
    padding: calc(var(--gap-padding) / 2) 0px;
}

.home header {
    background:var(--color-light)
}


.header {
    position: fixed;
    top: 2rem;
    width: 90%;
    width: calc(100% - 4rem);
    z-index: 999;
    transition:0.5s;
  }

  .header-sticky {
    top:0;
    transition:0.5s;
  }