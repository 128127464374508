.preloader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom right, #02AABD, #00CDAC);
    display: flex;
    justify-content: center;
    align-items: center;
}

.boxanimation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.boxanimation {
  background-color: #fff6e5;;
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#1C1C1C  ;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.preloader-text {
    position:absolute;
    font-size:1.5em;
}