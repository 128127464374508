h4.subtitle-border {
    border-top: 1px solid var(--color-border);
    padding-top: 20px;
    margin-top: 20px;
    font-size:1.5em;
} 

h4.subtitle {
    font-size:1.5em;
}

.box {
    min-width:220px;
    max-width:220px;
    margin:auto;
    min-height: 283px;
}

span.uptitle {
    color:var(--color-border);
}

.project-img {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    
}

.section-name-box {
    min-width:250px;
    max-width:250px;
}

.project-page .contact {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin-bottom:-20px;
}


@media only screen and (min-width:1024px) {
    .video-client-background {
        background: url('../../Assets/project-laptop.png');
        position: relative;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        max-width: 1190px;
        max-height: 600px;
        min-height: 600px;
        min-width: 1190px;
        width: 1190px;
        height: 600px;
        position: relative;
    }
    
      
      
    .project-video {
        position: absolute;
        left: 49.9%;
        top: 47.3%;
        transform: translate(-50%, -50%);
        width: 74.2%;
        height: 91.7%;
        border-top: 1rem !important;
        object-fit: cover;
    }
}


@media only screen and (max-width:768px) {
    .video-client-background {
        background: url('../../Assets/project-iphone.png');
        position: relative;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        max-width: 300px;
        max-height: 600px;
        min-height: 600px;
        min-width: 300px;
        width: 300px;
        height: 600px;
        position: relative;
    }
    
      
      
    .project-video {
        position: absolute;
        left: 49.9%;
        top: 49.9%;
        transform: translate(-50%, -50%);
        width: 90.2%;
        height: 95.8%;
        border-top: 1rem !important;
        object-fit: cover;
        border-radius: 2em;
    }
}