.about p {
    font-size:1em;
}

.about {
    padding:90px 0px;
}

.logo-technology {
    font-size:3em;
    
}

.logo-technology svg {
    margin:auto;
}

.about h2 {
    padding-bottom:40px;
}

.about .twitter, .about .messenger, .about .whatsapp, .about .linkedin, .about .resume {
    font-size: 1.7vw;
    color: var(--color-dark);
}

.about .social-media-container.flex.justify-center {
justify-content: flex-start;
padding:calc(var(--gap-padding) / 1) 0 0;
}

.about .linkedin {
    padding-left:0px;
}