.contact-details h4 {
    font-size:1.8vw;
}

.contact-details a {
    font-size:1em;
}

.icon-contact {
    font-size:1.2em;
}

.contact h2 {
    padding-bottom:50px;
}