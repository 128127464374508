body.home {
    background-color: var(--color-dark);
}


.herosction-container {
    height: 86vh;
    margin-bottom: 2rem;
    cursor:crosshair;
}

.herosction-container h1 {
    font-weight: 600;
    line-height: 4.4vw;
    font-size:4vw;
}

.herosction-container p {
    color:var(--color-dark);
    font-size:1.5vw;
}






  