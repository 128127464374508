.project-row {
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: center;
    opacity:1;
    transition: opacity 0.3s linear 0.5s;
    
    
}

.baboon:hover .project-row {
    opacity:0.5;
    transition: opacity 0.3s linear 0.5s;
}

.project-row span {
    text-align:right;
    padding-right:0px;
}




.project-row-hovered {
    position: absolute;
    top: -30%;
    transform: translateY(-50%);
    background:var(--color-dark);
    width: 700px;
    height: 354px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 999;
    padding:10px;
    border-radius:1rem;
    transition: transform 0.8s;
    transition: opacity 0.3s linear 2s;
  }

  
