@media only screen and (max-width:639px) {
    .desktop {
        display:none;
    }

    body {
        padding:1rem;
    }

    .header {
        padding:15px 15px !important;
        width: calc(100% - 2rem) !important;
    }

    .herosection {
        padding:0px 15px !important;
    }

    .herosction-container h1 {
        font-size:12vw !important;
        line-height:12vw !important;
        padding-bottom:50px !important;
    }

    span.delimiting {
        width: 100%;
        height: 25px;
        display: block ;
    }

    .herosction-container p {
        font-size: 1em !important;
        padding-right:15px;
    }

    div#about {
        padding-left:15px;
        padding-right:30px;
    }

    .about h2 {
        padding-bottom: 40px;
        font-size: 2.9em;
        line-height: 1.2em;
    }

    .contact h2 {
        padding-bottom: 50px;
        font-size: 2.9em;
        line-height: 1.2em;
    }

    .about .social-media-container.flex.justify-center {
        display:none;
    }

    .logo-section {
        padding-top:70px;
    }

    .counter-box h2 {
        font-size: 2.9em;
        line-height: 1.2em;
    }

    .counter-box {
        padding-bottom:50px;
    }

    .project-row {
        padding:0px 15px;
    }

    .project-row h3 {
        padding-bottom: 10px;
    }

    .contact {
        padding:0px 15px;
    }
    .contact-info-container {
        order:1;
        display:block;
    }

    .contact-form-container {
        order:2;
    }

    .contact-details span {
        font-size:14px !important;
    }

    .contact-details h4 {
        font-size:20px !important;
    }

    .contact-info-container {
        padding-bottom:50px;
    }

    .box {
        min-width: 80% !important;
        max-width: 80% !important;
        margin-right: auto !important;
        margin-left: 0px !important;
        min-height: auto !important;
        padding-bottom: 30px !important;
    }

    h4.subtitle-border {
        border-top: 1px solid var(--color-border);
        padding-top: 10px !important;
        margin-top: 10px !important;
        font-size: 1.3em !important;
        
    }

    .projectHeader {
        padding:0px 15px !important;
    }
    .mobile-project-header {
        padding-bottom:4rem !important;
    }

    .case-study .contact   {
        width: calc(100% + 2.2em);
        margin-left: -1em;
        padding-left: 2em !important;
        padding-right:2em !important;
    }

    .case-study .video-client-container   {
        width: calc(100% + 2.1em);
        margin-left: -1em;
    }

    .case-study footer {
        width: calc(100% + 2.1em);
        margin-left: -1em;
    }

    .case-study .footer-container {
        margin-bottom:0px !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }

    .case-study .about-client-container, .case-study #solution {
        padding-left: 1.2em;
        padding-right: 1.2em;
    }
}