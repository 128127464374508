.linkedin {
    padding-left:1em;
    padding-right:0.5em;
}

.whatsapp {
    padding-left:0.5em;
    padding-right:0.5em;
}

.twitter, .messenger {
    padding-left:0.5em;
    padding-right:0.5em;
}

.resume {
    padding-left:0.5em;
    padding-right:1em;
}

.twitter, .messenger, .whatsapp, .linkedin, .resume {
    font-size:1.25em;
    color:var(--color-light)
}

